.background {
  display: flex;
  width: 1024px;
  height: 450px;
  justify-content: center;
  background-image: url("../../assets/images/nxExpearienceBackground.png");
  background-position: center center;
  background-size: cover;
  position: relative;
}

.background::before {
  content: "";
  background-image: url("../../assets/images/nx-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 30px;
  width: 30px;
  top: 0;
  right: 0;
  transform: translate(-30px, 30px);
}

.cityname {
  align-self: center;
  position: absolute;
  color: grey;
  background-color: #ffffffbd;
  padding: 10px 20px;
  border-radius: 10px;
}

.clickbaleComponent {
  padding: 12px 24px;
  height: 40px;
  width: 40px;
  position: absolute;
  bottom: 175px;
  left: 50px;
}

.clickbaleComponent img {
  height: 100%;
  width: 100%;
}

/* .birds {
	height: 401px;
	width: 412px;
	background: url("../../assets/images/birds.png") no-repeat left top;
	position: absolute;
	top: 0px;
	left: 0px;
	transform: scale(0.3) translate(100px, -400px);
	animation: birds-animation 1s steps(3) infinite;
} */

.birdsgrenoble {
  height: 84px;
  width: 84px;
  background: url("../../assets/images/bird3u.png") no-repeat center center;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.18) translate(900px, 400px);
  animation: birdsgrenoble-animation 1s steps(3) infinite;
}

.birdsgrenoble1 {
  height: 116px;
  width: 84px;
  background: url("../../assets/images/bird1u.png") no-repeat left top;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.11) translate(-400px, 300px);
  animation: birdsgrenoble-animation 1s steps(3) infinite;
}

.birdsgrenoble2 {
  height: 57px;
  width: 84px;
  background: url("../../assets/images/bird2u.png") no-repeat left top;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.18) translate(2200px, 600px);
  animation: birdsgrenoble-animation 1s steps(3) infinite;
}

.birdsgrenoble2.b1 {
  transform: scale(0.18) translate(3500px, 300px);
}

.birdsgrenoble2.b2 {
  transform: scale(0.18) translate(3600px, 400px);
}

.birdsgrenoble2.b3 {
  transform: scale(0.18) translate(3700px, 300px);
}

.birdsgrenoble.b4 {
  transform: scale(0.15) translate(5650px, 800px);
}

.gb2 {
  transform: scale(0.18) translate(1000px, 300px);
}

.velovoiture {
  height: 400px;
  width: 560px;
  background: url("../../assets/images/velovoiture.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.3) translate(680px, 450px);
  animation: velovoiture-animation 1s steps(2) infinite;
}

.papy {
  height: 400px;
  width: 228px;
  background: url("../../assets/images/papy.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.23) translate(1340px, 625px);
  animation: papy-animation 1s steps(3) infinite;
}

.filsauvelo {
  height: 400px;
  width: 428px;
  background: url("../../assets/images/filsauvelo.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.2) translate(1000px, 740px);
  animation: filsauvelo-animation 1s steps(4) infinite;
}

.filleauchien {
  height: 400px;
  width: 288px;
  background: url("../../assets/images/filleauchien.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.22) translate(100px, 600px);
  animation: filleauchien-animation 1s steps(2) infinite;
}

.filsauskate {
  height: 400px;
  width: 325px;
  background: url("../../assets/images/filsauskate.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.21) translate(1150px, 600px);
  animation: filsauskate-animation 1s steps(5) infinite;
}

.birds.birdsgrenoblemini {
  transform: scale(0.1) translate(2900px, -300px);
}

.birdsbordeau {
  transform: scale(0.10) translate(2000px, 1000px);
}

.birdsplage {
  right: 0;
  left: unset;
}

.birdsplage.P1 {
  transform: scale(0.10) translate(-3000px, 1500px);
}

.birdsplage.P2 {
  transform: scale(0.10) translate(-2900px, 1600px);
}

.birdsplage.P3 {
  transform: scale(0.10) translate(-2800px, 1500px);
}

.birdsplage.P4 {
  transform: scale(0.15) translate(-2000px, 700px);
}

.birdsplage.P5 {
  transform: scale(0.15) translate(-2100px, 600px);
}

.birdsplage.P6 {
  transform: scale(0.15) translate(-1900px, 600px);
}

.homecat {
  height: 400px;
  width: 752px;
  background: url("../../assets/images/homecat.png") no-repeat left top;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transform: scale(0.12) translate(2370px, 1105px);
  animation: homecat-animation 1s steps(2) infinite;
}

.birds.birdsplage2 {
  transform: scale(0.15) translate(-500px, -500px);
  right: 0;
  left: unset;
}

.binome {
  height: 400px;
  width: 350px;
  background: url("../../assets/images/binome.png") no-repeat left top;
  position: absolute;
  bottom: 13px;
  left: 0px;
  transform: scale(0.28) translate(75px, 430px);
  animation: binome-animation 0.7s steps(2) infinite;
}

.message {
  height: 400px;
  width: 400px;
  background: url("../../assets/images/message.png") no-repeat left top;
  position: absolute;
  bottom: -5px;
  left: -30px;
  transform: scale(0.15);
  animation: message-animation 1s steps(3) infinite;
  cursor: pointer;
}

.msg2 {
  bottom: -20px;
  left: 20px;
}

.msgwomen1 {
  right: 30px;
  bottom: -10px;
  left: unset;
}

.msgwomen2 {
  right: 75px;
  bottom: -35px;
  left: unset;
}

.flagright,
.flagleft {
  height: 400px;
  width: 402px;
  background: url("../../assets/images/flag.png") no-repeat left top;
  position: absolute;
  transform: scale(0.3);
  animation: flag-animation 1s steps(6) infinite;
}

.flagleft.genobleflag {
  transform: scale(0.10) translate(-610px, 160px);
  left: 0;
}

.flagright {
  top: 0px;
  right: 0px;
  transform: scale(0.2) translate(-1000px, 0px);
}

.flagleft {
  top: 0px;
  right: 0px;
  transform: scale(0.2) translate(-300px, 0px);
}

.amp {
  height: 400px;
  width: 400px;
  background: url("../../assets/images/amp.png") no-repeat left top;
  position: absolute;
  /* transform: scale(0.15) translate(-980px, 20px); */
  animation: cup-animation 1s steps(3) infinite;
  top: 15px;
  left: 100px;
  cursor: pointer;
}

.gamp2 {
  top: -40px !important;
  left: 110px !important;
}

.ampwomen {
  top: unset;
  left: unset;
  right: -100px;
  bottom: -65px;
}

.message.papymsg {
  left: 265px;
  bottom: -115px;
}

.amp.ampthree {
  left: 100px;
  top: 135px;
}

.amp.grenobleamp {
  top: 140px;
  left: -100px;
}

.cafe.grenoblecafe {
  top: 60px;
  right: unset;
  left: 50px;
}

.grenoblemsg.msg1 {
  left: 240px;
  bottom: -60px;
}

.grenoblemsg.msg2 {
  bottom: -75px;
  left: 190px;
}

.transitionamp {
  right: -10px;
  left: unset;
  top: 160px;
}

.gcmsg {
  left: -10px;
  bottom: -90px;
}

.amp.gcamp {
  left: 250px;
  top: 160px;
}

.cafe.gccafe {
  top: 110px;
  right: unset;
  left: -185px;
}

.amp.brdcamp {
  top: 80px;
  left: 230px;
}

.cafe {
  height: 400px;
  width: 400px;
  background: url("../../assets/images/cafe.png") no-repeat left top;
  position: absolute;
  /* transform: scale(0.16) translate(-1390px, 600px); */
  animation: cafe-animation 1s steps(3) infinite;
  top: 10px;
  right: 150px;
  cursor: pointer;
}

.women {
  height: 400px;
  width: 400px;
  background: url("../../assets/images/women.png") no-repeat left top;
  position: absolute;
  transform: scale(0.25) translate(-170px, 715px);
  animation: women-animation 1s steps(2) infinite;
  top: 0;
  right: 0;
}

.SideMenuContainer {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 16px;
  z-index: 1;
}

/* .logoLink , */
.menuHead {
  margin: 0;
  height: 20%;
  background: #0e2661;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 1px #000000;
  cursor: pointer;
}

.joinus .menuHead .item-image img {
  width: 9px;
  height: 10px;
  top: 1px;
  object-fit: contain;
  transform: translateX(-10px);
}

.joinus span.item-name {
  color: #0de6b1;
}

.joinus .menuHead {
  background: #002fa7;
  height: 100%;
}

.sidemenu {
  height: 75px;
  width: 115px;
  position: fixed;
  font-size: 14px;
  z-index: 1;
  top: 7rem;
  right: 1.7rem;
}

.sidemenu.joinus {
  height: 15px;
  top: 5.5rem;
}

/* Super shitty but as the project will be erased and redone from beginning we save time */
@media screen and (max-width:1200px) {
  .sidemenu {
    right: 1.7rem;
  }
}

@media screen and (min-width:1201px) and (max-width:1400px) {
  .sidemenu {
    right: 1.58rem;
  }
}

@media screen and (min-width:1401px) and (max-width:1600px) {
  .sidemenu {
    right: 1.5rem;
  }
}

@media screen and (min-width:1601px) and (max-width:2000px) {
  .sidemenu {
    right: 1.1rem;
  }
}

@media screen and (min-width:2001px) and (max-width:2200px) {
  .sidemenu {
    right: 1rem;
  }
}

@media screen and (min-width:2201px) and (max-width:2400px) {
  .sidemenu {
    right: 0.9rem;
  }
}

@media screen and (min-width:2401px) {
  .sidemenu {
    right: 0.8rem;
  }
}

@media screen and (max-height: 720px) {
  .sidemenu {
    top: 7rem;
  }

  .sidemenu.joinus {
    top: 5.5rem;
  }


  .headerModel {
    font-size: 28px !important;
  }
}

@media screen and (min-height: 721px) and (max-height: 820px) {
  .sidemenu {
    top: 6.5rem;
  }

  .sidemenu.joinus {
    top: 5.1rem;
  }

}

@media screen and (min-height: 821px) and (max-height: 940px) {
  .sidemenu {
    top: 6rem;
  }

  .sidemenu.joinus {
    top: 4.5rem;
  }
}

@media screen and (min-height: 941px) and (max-height: 1080px) {
  .sidemenu {
    top: 5.2rem;
  }

  .sidemenu.joinus {
    top: 3.8rem;
  }
}


@media screen and (min-height: 1081px) and (max-height: 1300px) {
  .sidemenu {
    top: 4.7rem;
  }

  .sidemenu.joinus {
    top: 3.4rem;
  }
}

@media screen and (min-height: 1301px) and (max-height: 1440px) {
  .sidemenu {
    top: 4.1rem;
  }

  .sidemenu.joinus {
    top: 2.8rem;
  }
}



@media screen and (min-height: 1440px) {
  .sidemenu {
    top: 3.8rem;
  }

  .sidemenu.joinus {
    top: 2.5rem;
  }
}




.sidemenu.contactMenu {
  position: initial;
}

a.logoLink {
  position: fixed;
  top: 0;
  right: 0px;
  z-index: 1;
}

.logo {
  background-image: url("../../assets/images/nx-logo.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.welcomeMenu {
  width: auto;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.items {
  height: 80%;
  margin: 0;
}

.items ul {
  height: 100%;
  width: 100%;
}

.menu-item {
  background: #0e2661;
  height: calc((100% - 15px) / 3);
  display: flex;
  align-items: center;
  margin: 4px 0px;
  border: solid 1px #000000;
  cursor: auto;
}

.item-image {
  height: 80%;
  width: 20%;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(20%);
}

.menuHead .item-image img {
  height: 57%;
  width: auto;
  position: absolute;
  right: 0;
  top: 1px;
  transform: translate(-10px, 2px);
}

.item-image img {
  width: 100%;
  height: 100%;
}

.menu-item:nth-child(1) .item-image {
  background-image: url("../../assets/images/temoignage.png");
}

.menu-item:nth-child(2) .item-image {
  background-image: url("../../assets/images/esprit-tech.png");
}

.menu-item:nth-child(3) .item-image {
  background-image: url("../../assets/images/evenement.png");
}

.item-name {
  padding-top: 1px;
  width: 80%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(10%);
  opacity: 0.6;
}

.menuHead .item-name,
.item-name:hover {
  opacity: 1;
}

.Hide {
  display: none;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.ShowB {
  display: block;
}

.ShowF {
  display: flex;
}

.ReactModal__Content {
  top: 6rem;
  box-shadow: -3px -3px 9px 0 rgba(0, 0, 0, 0.22) !important;
  border-radius: 0px !important;
  border: none !important;
  width: 50% !important;
  margin: 0 auto !important;
  height: 60% !important;
  transform: translateY(8%) !important;
  padding: 30px !important;
}

.ReactModal__Overlay {
  background: rgb(255, 255, 255, 0) !important;
}

.footerM {
  width: 100%;
  height: 8%;
  background: #0d2359;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.neoxia {
  text-decoration: none;
  color: #fff;
  position: relative;
  display: block;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.neoxia a {
  text-decoration: none;
  color: #fff;
  position: relative;
  display: block;
  width: auto;
  font-size: 20px;
}

.neoxia a::after {
  position: absolute;
  content: "";
  width: 100%;
  border-bottom: 3px solid #fff;
  bottom: -3px;
  left: 0px;
}

.footerModel {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  transform: translateX(20px);
}

.footerModel p {
  width: max-content;
  padding: 0 10px;
  font-size: 20px;
  letter-spacing: 1.5px;
}

.footerModel p img {
  height: 18px;
  width: auto;
  object-fit: cover;
}

.closeModel {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  order: 2;
  z-index: 2;
  margin-left: auto;
  margin-bottom: auto;
}

.closeModel img {
  width: 100%;
  height: 100%;
}

.moreSectionModel {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: -0.46px;
  padding-top: 21px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.moreSectionModel button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
}

.headerModel {
  align-items: flex-start;
  color: #0e2661;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: -0.46px;
  text-align: center;
  height: 8%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.moreSectionModel {
  align-items: flex-end;
  color: #0de6b1;
  font-size: 25px;
  /* height: 95px; */
}

.moreSectionModel p {
  margin: 0;
}

.footerModel p a {
  text-decoration: none;
  color: #fff;
  position: relative;
  display: block;
}

.titleModel {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  height: 90px;
}

.temTitle {
  /* width: 650px; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moreSectionModel span {
  display: block;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
}

.moreSectionModel span::after,
.moreSectionModel span::before {
  content: "";
  position: absolute;
}

.moreSectionModel span::after {
  border-bottom: 1.5px solid #0de6b1;
  width: 100%;
  left: 0;
  bottom: 0;
}

.moreSectionModel a {
  color: unset;
  text-decoration: unset;
}

.moreSectionModel span::before {
  background: url("../../assets/images/plus.png");
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0;
  top: 0;
  transform: translateX(-25px);
}

.contentModel {
  height: calc(85% - 60px);
  position: relative;
  margin: 0 -30px;
}

.tech.contentModel {
  margin: 0 auto;
  width: 100%;
}

.articles {
  /* display: block; */
  height: 100%;
}

.tech.moreSectionModel {
  height: 65px;
}

.grp {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.article {
  height: 95%;
  width: 50%;
  margin: 0 auto;
}

.article a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.article img {
  width: 84%;
  border-radius: 2px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.videoPlayer video {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  outline: none;
  object-fit: cover;
}

.videoPlayer {
  height: 100%;
  width: 75%;
  margin: 0 auto;
  position: relative;
}

.playButton {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  /* z-index: 2; */
}

.playButton img {
  width: 75px;
  height: 75px;
}

.galerie {
  height: 100%;
  width: 100%;
  display: block;
}

.slick-slider,
.slick-slider .slick-track,
.slick-slider .slick-list,
.slick-slide div {
  height: 100% !important;
}

.slick-slide {
  transform: scale(0.95);
}

.slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1);
}

.slick-slide img {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.slick-slide.slick-active.slick-center.slick-current img {
  opacity: 1;
}

.slick-prev:before {
  background: url("../../assets/images/galerie-leftArrow.png") center center no-repeat;
  left: 0;
}

.slick-next:before {
  background: url("../../assets/images/galerie-rightArrow.png") center center no-repeat;
  right: 0;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-size: contain;
  height: 45px;
  width: 45px;
  position: absolute;
  top: 0;
  opacity: 1;
}

.slick-prev,
.slick-next {
  width: 45px !important;
  height: 45px !important;
  z-index: 2;
}

.slick-next {
  right: 0px !important;
}

.slick-prev {
  left: 0px !important;
}

.item {
  width: 550px !important;
}

.PortraitMessage {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: center;
  background: #000000ad;
  display: flex;
  left: 0;
  z-index: 2;
}

.Infomessage {
  width: 50%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
}

/* Animations */

@keyframes birds-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -500px 0;
  }
}

@keyframes birdsgrenoble-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -252px 0;
  }
}

@keyframes velovoiture-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1119px 0;
  }
}

@keyframes papy-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -684px 0;
  }
}

@keyframes filsauvelo-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1713px 0;
  }
}

@keyframes filleauchien-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -589px 0;
  }
}

@keyframes homecat-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1503px 0;
  }
}

@keyframes filsauskate-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1628px 0;
  }
}

@keyframes flag-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -2520px 0;
  }
}

@keyframes cup-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1316px 0;
  }
}

@keyframes binome-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -816px 0;
  }
}

@keyframes message-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -1417px 0;
  }
}

@keyframes cafe-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    /* background-position: -1316px 0; */
    background-position: -1316px 0;
  }
}

@keyframes women-animation {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -989px 0;
  }
}

.MyContainer {
  width: 100%;
  height: 100%;
  z-index: 0;
}